.about {
    max-width: 1300px;
    margin: 0 auto;

    .about-content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 150px 0px 0px 0px;

        .about-image img {
            display: block;
            width: 100%;
        }

        .about-text-content {
            max-width: 515px;

            .about-capture {
                font-size: 40px;
                font-style: normal;
                font-weight: 600;
                padding: 0px 0px 20px 0px;
            }

            .about-capture span {
                color: #34A573;
            }

            .about-description {
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%;
            }

            .about-numbers {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;
                padding: 60px 0px 0px 0px;

                .statistics-item-wrapper {
                    padding: 0px 60px 40px 0px;
                    .statistics-capture {
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 130%;
                        border-bottom: 1px solid #D1D1D1;
                    }

                    .values-block {
                        padding: 6px 0px 0px 0px;
                        font-size: 60px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 100%;
                        color: #34A573;
                        .value{
                            display: flex;
                        }
                    }
                }
            }
        }

    }
    @media (max-width:1076px) {
        .about-content{
            flex-direction: column-reverse;
            .about-text-content{
                margin: 0 auto;
                max-width: calc(100% - 40px);
                .about-numbers{
                    flex-direction: column;
                    justify-content: start;
                    align-items: flex-start;
                    .statistics-item-wrapper{
                        width: 100%;
                        padding: 0px;
                        .values-block {
                            font-size: 36px;
                            display: flex;
                            padding-bottom: 20px;
                        }
                    }
                }
            }
            
        }
    }
    @media (max-width:460px) {
        .about-content{
            padding: 60px 0px 0px 0px;
            .about-text-content{
                .about-capture {
                    font-size: 30px;
                }
            }
        }
    }
}