.block {
    width: 100vw;
    max-width: 1440px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: justify;
    font-size: 20px;
    padding-top: 160px;
  }
  .content {
    margin-top: 40px;
    width: calc(100% - 240px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
  }
  .back a {
    text-decoration: none;
  }
  .content h2 {
    font-size: 40px;
    font-weight: 500;

    font-style: normal;
    margin: 0;
    line-height: 46.88px;
    color: rgba(40, 40, 40, 1);
  }
  .content h3 {
    text-align: center;
    font-size: 40px;
    font-weight: 500;

    font-style: normal;
    margin: 0;
    line-height: 46.88px;
    color: rgba(40, 40, 40, 1);
  }
  .content ul {
    width: 100%;
    padding-left: 20px;
  }
  .content p {
    margin: 10px 0;
    width: 100%;
    text-align: start;
  }
  .content a {
    color: rgba(128, 0, 128, 1);
    display: block;
    max-width: 90%;
  }
  
  @media (max-width: 820px) {
    .content {
      width: calc(100% - 80px);
    }
  }
  
  @media (max-width: 480px) {
    .content {
        width: calc(100% - 80px);
    }
    .content h2 {
      font-size: 25px;
      line-height: 30px;
    }
    .content h3 {
      text-align: center;
      font-size: 25px;
      line-height: 30px;
    }
  }
  