.saveCredit{
    padding: 200px 0px 0px 0px;
    margin-bottom: -34px;
    background-color: #34A573;
    text-align: center;
    .saveCredit-capture{
        max-width: 716px;
        margin: 0 auto;
        font-size: 48px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        color: #fff;
        padding: 0px 0px 56px 0px;
    }
    @media (max-width:600px) {
        .saveCredit-capture{
            font-size: 34px;
        }
    }
}
.saveCredit p:last-child{
    display: none
}