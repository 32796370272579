.button-component button {
    width: 320px;
    border: none;
    background-color: #34A573;
    border-radius: 15px;
    padding: 20px 0px 20px 0px;
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}