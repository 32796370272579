.offer {
    max-width: 1300px;
    margin: 0 auto;
    padding: 100px 0px 160px 0px ;
    .offer-content {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;

        .offer-text-content {
            max-width: 570px;
            margin: 0 auto;

            .offer-capture {
                font-size: 40px;
                font-style: normal;
                font-weight: 600;
                padding: 0px 0px 20px 0px;
            }

            .offer-capture span {
                color: #34A573;
            }

            .offer-description {
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 130%;
                padding: 0px 0px 30px 0px;
            }
        }

        .offer-image {
            margin: 0 auto;
        }

        .offer-image img {
            display: block;
            width: 100%;
        }
    }
    @media (max-width:1075px) {
        .offer-content{
            width: calc(100% - 40px);
            margin: 0 auto;
            .offer-text-content{
                padding: 0px 0px 30px 0px;
            }
            .offer-button button{
                margin:  0 auto;
            }
        }
    }
    @media (max-width:460px) {
        .offer-content{

            .offer-text-content{
                .offer-capture{
                    font-size: 30px;
                }
            }
        }
    }
}