.headerNav{

    position: relative;
}
.headerNav li{
    padding: 0px 10px 6px 10px;
    display: inline-block;
    color:#fff;
    cursor: pointer;
    font-size: 16px;
font-style: normal;
font-weight: 400;
}
.headerNav li:hover{
    color: #34A573;
}
.headerNav li img{
    position: absolute;
    
    left: 20px;
    top: 20px;
}
.headerNav li a:hover{
    color: #34A573;
}
.headerNav li a {
    text-decoration: none;
    color: #fff;
}