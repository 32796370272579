.not-found-page {
    padding: 200px 0px 90px 0px;

    .not-found-page-content {
        width: 100%;

        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-around;
        .not-found-page-img img {
            display: block;
            width: 100%;
        }

        .not-found-page-text {
            max-width: 400px;


            .not-found-page-capture {

                font-size: 44px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                padding: 0px 0px 10px 0px;
            }

            .not-found-page-description {
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                padding: 0px 0px 10px 0px;
            }
        }

        .not-found-page-text a {
            text-decoration: none;
            color: #fff;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%;
        }
        .link-go-back{
            margin-top: 30px;
            width: 100%;
            background-color:#34A573;
            padding: 15px 0px 15px 0px;
            text-align: center;
            border-radius: 15px;
        }
    }
}