.advantages {
    max-width: 1200px;
    margin: 0 auto;
    padding: 150px 0px 0px 0px;

    .advantages-content {
        width: 100%;

        .advantages-cepture {
            font-size: 40px;
            font-style: normal;
            font-weight: 600;
        }

        .advantages-cards {
            padding: 40px 0px 0px 0px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;

            .card-wrapper {
                width: 570px;
                height: 320px;
                border-radius: 15px;
                background: #F5F5F7;
                margin: 0px 0px 40px 0px;

                .card-content {
                    padding: 40px 40px 22px 40px;

                    .card-img {
                        padding: 0px 0px 15px 0px;
                    }

                    .card-img img {
                        display: block;
                        width: 56px;
                        height: 56px;
                    }

                    .card-capture {
                        font-size: 25px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                        padding: 0px 0px 15px 0px;
                    }

                    .card-description {
                        font-size: 18px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 130%;
                    }
                }
            }
        }
    }

    @media (max-width:1075px) {
        .advantages-content {
            margin: 0 auto;
            width: calc(100% - 40px);

            .advantages-cepture {
                font-size: 36px;
            }

            .advantages-cards {

                .card-wrapper {
                    height: auto;
                    margin: 20px auto;
                }
            }
        }
    }

    @media (max-width:460px) {
        .advantages-content {
            .advantages-cepture {
                font-size: 30px;
            }

            .advantages-cards {

                .card-wrapper {
                    .card-content {
                        .card-capture {
                            font-size: 20px;

                        }

                        .card-description {
                            font-size: 16px;

                        }
                    }
                }
            }
        }
    }
}